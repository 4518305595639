*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.demo-logo-vertical {
    text-align: center;
    /* color: #A6ADB4; */
    /* color: white; */
    /* color: #65a5ff; */
    color: white;
    /* color: #0AB39C; */
    font-size: 30px;
    line-height: 64px;
}

.logged-container{
    display: flex;
    background-color: #F3F3F9;
    padding: 0 20px;
}

.logged-icon{
    font-size: 35px;
    margin-left: 10px;
    /* color: #1677FF; */
    color: #405189;
    /* color: #0AB39C; */
}

.logged-user {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
    /* display: block; */
    margin: 0;
    padding: 0;
    height: 64px;
}

.logged-name {
    /* display: block; */
    height: auto;
    font-weight: bold;
    /* color: #252525; */
    margin: 0;
    /* height: 32px; */
    line-height: 20px;
}

.logged-role {
    height: auto;
    /* display: block; */
    /* height: 32px; */
    line-height: 20px;
    color: #65a5ff;
    color: #838383;
    font-weight: 500;
    font-size: 12px;
    margin: 0;
}

.filter-container{
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
}

.copy {
    /* padding: 5px; */
    /* border-radius: 5px; */
    font-size: 12px;
}

.copy:hover {
    /* background-color: #ebebeb; */
}

.main-filters{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.search-input {
    width: 40%;
}

.main-buttons{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 20px;
}

.custom-table {
    font-size: 12px;
}

.custom-table .ant-table-cell {
    font-size: 12px; /* Promjena veličine fonta za ćelije */
}

.custom-table .ant-table-thead > tr > th {
    font-size: 12px; /* Promjena veličine fonta za zaglavlja */
}

.custom-table .ant-table-expanded-row p {
    font-size: 12px; /* Promjena veličine fonta za prošireni red */
}


.pagination-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    margin-top: 10px;
  }
  
  .total-items {
    flex: 1;
    text-align: left;
    font-size: 13px;
  }
  
  .page-size-selector {
    flex: 1;
    text-align: right;
    /* font-size: 12px; */
  }

  .header-container{
    display: flex;
    /* justify-content: flex-end; */
    font-size: 12px;
    padding: 0 10px;
    /* color: #838383; */
  }

  .ant-table th {
    /* white-space: nowrap; */
  }

  .min-width-column {
    min-width: 60px!important;
  }


  .page-info{
    background-color: white;
    border-top: 1px solid rgba(233, 233, 233, 0.734);
    padding: 10px 20px;
    color: #1677FF;
    color: #495057;
    text-transform: uppercase;
    font-size: 12px;
  }

  @media only screen and (max-width: 480px) {

    .sider {
        position: fixed!important;
        /* width: 100vw!important; */
        z-index: 100;
    }

    .main-layout{
        margin-left: 0!important;
    }

    .main-filters{
        flex-direction: column;
    }

    .search-input{
        width: 100%;
        margin-bottom: 20px;
    }

    .pagination-container{
        flex-direction: column;
    }

    .total-items{
        margin-bottom: 10px;
    }

    .page-size-selector{
        margin-top: 10px;
    }

    .select-glasfaser{
        width: 100%;
    }
  }