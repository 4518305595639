/* @import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap'); */

@font-face {
    font-family: 'Poppins';
    /* src: url('./fonts/Poppins-Regular.ttf') format('truetype'); */
    src: url('../fonts/Poppins/Poppins-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
}

.wave{
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    /* left: -5%; */
    /* bottom: 0; */
    /* height: 100%; */
    /* margin: 0; */
    /* padding: 0; */
    /* width: 50%; */
}

.login-page{
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 100vh;
    /* margin-top: 8vh; */
    /* padding-right: 8vw; */
    /* background: radial-gradient(circle at 10% 20%, rgb(255, 200, 124) 0%, rgb(252, 251, 121) 90%); */
    /* background: radial-gradient(circle at 10% 20%, rgb(255, 255, 255) 0%, rgba(242, 242, 242, 0.86) 90%); */
    /* background: radial-gradient(circle at 10% 20%, rgb(255, 255, 255) 0%, rgba(255, 250, 239, 0.86) 90%); */
    /* background-color: #FFFFDB; */
    /* background: radial-gradient(circle at 10% 20%, rgb(255, 255, 255) 0%, rgba(253, 233, 189, 0.86) 90%); */
    /* background: radial-gradient(circle at 10% 20%, rgba(255, 254, 252, 0.86) 0%, rgba(255, 238, 197, 0.86) 90%); */
    /* position: relative; */
    /* left: 0; */
    /* background-color: #6f6f6f; */
}

.worker-img{
    z-index: 100;
    width: 50%;
    /* background-color: #FFAE1F; */
    /* background-color: #1677ff3c; */
    /* background-color: #1677FF; */
    height: 100vh;
    margin: 0;
    
}

.image-container{
    background-image: url("../img/background04-4.webp");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    /* position: absolute; */
    /* top: 0; */
    /* left: 0; */
    width: 50%;
    height: 100vh;
}

.image-cover{
    position: absolute;
    left: 0;
    top: 0;
    width: 50vw;
    background-color: #1677FF;
    z-index: 10;
}

/* .log-bcg{
    width: 45%;
} */

.login-box{
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* background-color: white; */
    /* background-color: #1677FF; */
    border: none;
    position: relative;
}

.login-form{
    width: 55%;
    /* background-color: white; */
    /* padding: 20px; */
    /* border-radius: 5px; */
}

.powered{
    position: absolute;
    bottom: 30px;
    /* color: #6f6f6f; */
    font-size: 14px;
    /* color: white; */
}

.login-box h1{
    /* margin-bottom: 40px; */
    /* font-size: 55px; */
    /* font-size: 40px; */
    font-size: 35px;
    margin-bottom: 10px;
    font-weight: 300;
    color: rgb(53, 53, 53);
    /* text-transform: uppercase; */
    /* font-size: 30px; */
    font-weight: bold;
}

.realbau-app{
    /* margin-bottom: 40px; */
    font-size: 14px;
    /* font-weight: 300; */
    color: rgb(53, 53, 53);
    color: rgb(94, 94, 94);
}

.login-alert-container{
    width: 55%;
    margin: 10px 0;
}

.login-alert{
    /* display: none; */
}

.gf-container {
    position: absolute;
    left: 0;
    bottom: 30px;
    bottom: 0;
    /* top: 30px; */
    /* bottom: 0; */
    /* width: 100%; */
    width: 50%;
    background-color: rgba(255, 255, 255, 0.288);
    display: flex;
    justify-content: space-between;
    z-index: 111;
    padding-left: 2%;
    padding-right: 2%;
    /* background-color: rgba(234, 234, 234, 0.782); */
    /* background-color: rgba(255, 255, 255, 0.537); */
    padding: 10px;
    border-right-color: 1px solid #1677FF;
}

.gff{
    width: 20%;
}

.gf{
    width: 10%;
    position: fixed;
    top: 85vh;
    left: 5%;
    height: auto;
    z-index: 111;
}

/* .gfp{
    left: 20%;
} */

.avatar{
    width: 13%;
    margin-bottom: 20px;
}

.login-form-button{
    width: 100%;
}

/* .login-form{
    width: 50%;
} */

/* .login-inp{
    position: relative;
    margin-bottom: 20px;
    width: 100%;
}

.login-inp input {
    width: 100%;
    height: 50px;
    padding-left: 35px;
    border: none;
    border-bottom: 2px solid rgb(221, 221, 221);
    outline: none;
    border-radius: 5px;
    transition: 0.5s;
    font-size: 14px;
}


.login-inp input:focus{
    font-size: 16px;
}


.login-inp input:focus {
    border-color: #FFAE1F;
} */

/* input:focus + .login-icon {
    color: #FFAE1F!important;
}  */

/* .input-label{
    color: rgb(119, 119, 119);
    font-size: 15px;
    z-index: 1;
    position: absolute;
    top: 30%;
    left: 10%;
    pointer-events: none;
    transition: 0.5s;
} */

/* .login-form button{
    width: 100%;
    height: 50px;
    cursor: pointer;
    margin-bottom: 70px;
    margin-top: 30px;
    border: none;
    background-color: #FFAE1F;
    color: white;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 40px;
    outline: none;
} */

/* .login-form button:hover{
    background: radial-gradient(circle at 10% 20%, #FFAE1F 0%, #ffba6a 90%);
} */

.expired{
    font-size: 14px;
    /* color: #FFAE1F; */
    /* color: #1677FF; */
    color: red;
    /* background-color: #f7f7f783; */
    padding: 5px;
    border-radius: 10px;
}

/* .inputs{
    display: flex;
    align-items: center;
    position: relative;
    margin-top: 5px;
} */

/* .login-icon{
    position: absolute;
    font-size: 20px;
    left: 5px;
    color: #6f6f6f;
} */





@media only screen and (max-width: 1000px) {

    .login-page{
        justify-content: center;
        padding: 0;
    }

    .avatar{
        width: 30%;
        margin-bottom: 20px;
    }

    .img{
        display: none;
    }

    .login-box{
        width: 80%;
    }

    .login-form{
        width: 100%;
        margin-left: 0;
    }

    .login-inp label{
        font-size: 13px;
    }

    .image-container{
        width: 0;
    }
    
    .login-box{
        width: 90%;
    }
    
    .login-form{
        width: 100%;
    }

}