

.isDoneRep{
    
}

.isDoneRep:hover{
    color: #2985FF;
}

.doneRep{
    color: rgb(30, 194, 30);
}

.nonDoneRep{
    color: rgb(162, 162, 162);
    transition: 0.3s;
}

.nonDoneRep:hover{
    color: #2985FF;
}